<template>
  <!-- 奖品核销 -->
  <div>
    <!--  顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx-row ali-c">
          <el-input
            class="search-input"
            placeholder="请输入电话号码"
            v-model="form.phone"
          ></el-input>
          <el-input
            class="search-input ml-10"
            placeholder="请输入奖品名称"
            v-model="form.giftName"
          ></el-input>
          <el-date-picker
            class="ml-10"
            v-model="time"
            value-format="yyyy-MM-dd hh:mm:ss"
            :default-time="['00:00:00','23:59:59']"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
          <el-button
            type="primary"
            icon="el-icon-search"
            class="ml-10"
            @click="getList"
            >查询</el-button
          >
          <el-button icon="el-icon-refresh" class="ml-10" @click="reset"
            >重置</el-button
          >
        </div>
      </el-col>
    </el-row>
    <!-- 表格内容 -->
    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column label="用户" prop="username"></el-table-column>
            <el-table-column label="联系方式" prop="phone"></el-table-column>
            <el-table-column
              label="核销奖品"
              prop="prizeName"
            ></el-table-column>
            <el-table-column label="奖品来源" prop="source"></el-table-column>
            <el-table-column
              label="核销人员"
              prop="offsetPeople"
            ></el-table-column>
            <el-table-column
              label="核销时间"
              prop="offsetTime"
            ></el-table-column>
          </el-table>
          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="form.pageNum"
              :pageSize="form.pageSize"
              :pageSizes="[10, 20, 30, 50, 100]"
              @changePage="getList"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import { getAwardsList } from "@/api/verify_center/verify_records";
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      // 顶部搜索栏数据
      form: {
        giftName: "", //	string	true	普通参数		奖品名
        phone: "", //	string	true	普通参数		用户手机号码
        startTime: "", //	string	true	普通参数		开始时间
        endTime: "", //	string	true	普通参数		结束时间
        pageNum: 1, //	string	true	普通参数	1
        pageSize: 10, //	string	true	普通参数	10
      },
      time: [], //时间组件容器
      // 优惠券表格数据内容
      tableData: [],
      // 会员-当前分页与总条数
      total: 0,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 初始化数据
    getList() {
      this.form.startTime = this.time.length ? this.time[0] : "";
      this.form.endTime = this.time.length ? this.time[1] : "";
      getAwardsList(this.form).then((res) => {
        this.total = res.data.body.total; //总条数
        this.tableData = res.data.body.list;
      });
    },
    // 重置搜索关键词
    reset() {
      this.form = {
        giftName: "", //	string	true	普通参数		奖品名
        phone: "", //	string	true	普通参数		用户手机号码
        startTime: "", //	string	true	普通参数		开始时间
        endTime: "", //	string	true	普通参数		结束时间
        pageNum: 1, //	string	true	普通参数	1
        pageSize: 10, //	string	true	普通参数	10
      };
      this.time = [];
      this.getList();
    },
    syncPageData(data) {
      this.form.pageNum = data.num;
      this.form.pageSize = data.size;
    },
  },
};
</script>
<style lang="scss" scoped>
.member-table {
  .circle {
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }
}
</style>
